.custom-background {
    background-image: url("../image/main_bg.png");
    background-position: center center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
}
.d-none{
    display: none !important;
}
#updating{
    position: fixed;
    left: 10px;
    bottom: 10px;
}
